import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';
import { Loader } from 'src/components/SuspenseLoader/Loader';
import { lazy } from 'react';

import AuthGuard from './layouts/Auth/AuthGuard';
import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

const VerifyAccount = Loader(lazy(() => import('src/content/public/VerifyAccount')));
const ConfirmPwordReset = Loader(lazy(() => import('src/content/public/ConfirmPwordReset')));
const ForgotPassword = Loader(lazy(() => import('src/content/public/ForgotPassword')));
const JwtLogin = Loader(lazy(() => import('src/content/public/JwtLogin')));
const JwtRegister = Loader(lazy(() => import('src/content/public/JwtRegister')));

// Dashboard
const Dashboard = Loader(lazy(() => import('src/content/dashboard')));

// Settings
const GeneralSettings = Loader(lazy(() => import('src/content/settings')));

// Admins
const AdminManagement = Loader(lazy(() => import('src/content/admins')));
const CreateAdmin = Loader(lazy(() => import('src/content/admins/create/CreateAdmin')));
const AdminProfile = Loader(lazy(() => import('src/content/admins/profile')));

// Teams
const TeamManagement = Loader(lazy(() => import('src/content/teams')));
const TeamProfile = Loader(lazy(() => import('src/content/teams/profile')));
const CreateTeamForm = Loader(lazy(() => import('src/content/teams/create/CreateTeamForm')));
const TeamAssociations = Loader(lazy(() => import('src/content/associations')));

// Status
const Status404 = Loader(lazy(() => import('src/layouts/Status404')));

const routes: RouteObject[] = [
  {
    element: (<AuthGuard>
      <BaseLayout />
    </AuthGuard>),
    children: [
      {
        path: 'dashboard',
        element: <SidebarLayout />,
        children: [
          {
            path: '',
            element: <Dashboard />
          }
        ]
      },
      {
        path: 'settings',
        element: <SidebarLayout />,
        children: [
          {
            path: '',
            element: <GeneralSettings />
          }
        ]
      },
      {
        path: 'associations',
        element: <SidebarLayout />,
        children: [
          {
            path: '',
            element: <TeamAssociations />
          },
        ]
      },
      {
        path: 'admins',
        element: <SidebarLayout />,
        children: [
          {
            path: 'manage',
            element: <AdminManagement />
          },
          {
            path: 'create',
            element: <CreateAdmin />
          },
          {
            path: 'profile/:queryUID',
            children: [
              {
                path: '',
                element: <AdminProfile />
              }
            ]
          }
        ]
      },
      {
        path: 'teams',
        element: <SidebarLayout />,
        children: [
          {
            path: '',
            element: <TeamManagement />
          },
          {
            path: 'create',
            element: <CreateTeamForm />
          },
          {
            path: 'profile/:queryUID',
            children: [
              {
                path: '',
                element: <TeamProfile />
              }
            ]
          }
        ]
      },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
        ]
      }
    ]
  },
  { path: `/signup/:lang`, element: <JwtRegister /> },
  { path: `/signup`, element: <Navigate to='/signup/en' /> },
  { path: `/signin/:lang`, element: <JwtLogin /> },
  { path: `/signin`, element:  <Navigate to='/signin/en' /> },
  { path: `/forgot-password/:lang`, element: <ForgotPassword /> },
  { path: `/forgot-password`, element: <Navigate to='/forgot-password/en' /> },
  { path: `/verify/:lang/:token`, element: <VerifyAccount /> },
  { path: `/reset/:lang/:token`, element: <ConfirmPwordReset /> },
  { path: '/404', element: <Status404 /> },
  { path: '/', element: <Navigate to="dashboard" replace /> },
  { path: '*', element: <Status404 /> }
];

export default routes;
